import N4Layout from 'components/layout/n4_layout';
import logger from 'lib/logger';
import { nonSbStaticProps } from 'lib/storyblok';

const log = logger({ category: '404' });

const Custom404 = () => (
  <div style={{ textAlign: 'center', height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Page Not Found</div>
);

Custom404.getLayout = (children, pageProps) => <N4Layout {...pageProps}>{children}</N4Layout>;

const getStaticProps = async () => {
  const slug = '/404';
  const results = await nonSbStaticProps({ slug, log });
  Object.assign(results.props, {
    is404: true,
    meta: {
      noindex: true,
      meta_title: 'Page Not Found',
    },
  });
  return results;
};

export { getStaticProps };

export default Custom404;
